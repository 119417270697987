@import url("https://fonts.googleapis.com/css2?family=Carter+One&family=Raleway:wght@400;600;700&display=swap");
@import "~@angular/material/prebuilt-themes/indigo-pink.css";
@import "node_modules/ngx-countries-dropdown/assets/styles.css";

@font-face {
  font-family: "Material Icons";
  font-style: normal;
  font-weight: 400;
  src: url(https://fonts.gstatic.com/s/materialicons/v55/flUhRq6tzZclQEJ-Vdg-IuiaDsNc.woff2)
    format("woff2");
}

* {
  font-family: "Manrope", sans-serif;
}

.mat-raised-button[disabled] {
  cursor: not-allowed !important;
}

button {
  height: 40px !important;
  letter-spacing: -0.16px !important;
  font-size: 1rem !important;
}

.dropdown-hover:hover {
  background-color: rgba(76, 78, 100, 0.05);
  cursor: pointer;
}

.tour-step {
  /* width: 350px!important; */
  max-width: 400px !important;
  /* border-radius: 12px!important; */
}

.tour-step div {
  padding: 0;
  border-radius: 12px;
  /* font-size: 12px;  */
  /* border: none;
  box-shadow: none; */
}

.material-icons {
  font-family: "Material Icons";
  font-weight: normal;
  font-style: normal;
  font-size: 24px;
  line-height: 1;
  letter-spacing: normal;
  text-transform: none;
  display: inline-block;
  white-space: nowrap;
  word-wrap: normal;
  direction: ltr;
  -webkit-font-feature-settings: "liga";
  -webkit-font-smoothing: antialiased;
}

* {
  box-sizing: border-box;
}

html,
body {
  background-color: rgb(250, 250, 250);
}

body::-webkit-scrollbar {
  /* display: none; */
}

*::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar {
  height: 6px;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  background: #eaeaec !important;
  border-radius: 2px;
}

.modal {
  max-height: 86%;
}

body a {
  text-decoration: none !important;
}

.active,
.collapsible:hover {
  color: #ffe239;
  background-color: transparent;
}

button:focus {
  background-color: white;
}

.input-field .prefix.active {
  color: #5459d1;
}

input:not([type]):focus:not([readonly]),
input[type="text"]:not(.browser-default):focus:not([readonly]),
input[type="password"]:not(.browser-default):focus:not([readonly]),
input[type="email"]:not(.browser-default):focus:not([readonly]),
input[type="url"]:not(.browser-default):focus:not([readonly]),
input[type="time"]:not(.browser-default):focus:not([readonly]),
input[type="date"]:not(.browser-default):focus:not([readonly]),
input[type="datetime"]:not(.browser-default):focus:not([readonly]),
input[type="datetime-local"]:not(.browser-default):focus:not([readonly]),
input[type="tel"]:not(.browser-default):focus:not([readonly]),
input[type="number"]:not(.browser-default):focus:not([readonly]),
input[type="search"]:not(.browser-default):focus:not([readonly]),
textarea.materialize-textarea:focus:not([readonly]) {
  /* border-bottom: 1px solid #5459D1;
  box-shadow: 0 1px 0 0 #5459D1; */
  height: auto !important;
  box-shadow: none;
}

.page_title {
  color: #01023f;
  text-align: center;
  position: relative;
  padding-bottom: 40px;
}

.page_title:after {
  content: "";
  background-image: url(./assets/images/title_bottom.png);
  background-repeat: no-repeat;
  background-position: bottom center;
  background-size: auto 25px;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 5px;
  height: 25px;
}

.empty_list {
  border-radius: 3px;
  background-color: #fff;
  overflow: hidden;
  text-align: center;
  padding: 30px;
}

.md-drppicker.shown.drops-down-right {
  left: unset !important;
}

/* .ngx-pagination .current {
  background-color: #0077b6 !important;
} */

html,
body {
  height: 100%;
}

body {
  margin: 0;
  /* font-family: Roboto, "Helvetica Neue", sans-serif !important; */
}

::ng-deep .mat-dialog-container {
  border-radius: 10px !important;
}

/* Loader */
.boxes {
  --size: 32px;
  --duration: 800ms;
  height: calc(var(--size) * 2);
  width: calc(var(--size) * 3);
  position: relative;
  transform-style: preserve-3d;
  transform-origin: 50% 50%;
  margin-top: calc(var(--size) * 1.5 * -1);
  transform: rotateX(60deg) rotateZ(45deg) rotateY(0deg) translateZ(0px);
}

.boxes.pr-style {
  align-content: center !important;
  text-align: center !important;
  left: 638px !important;

  z-index: 5 !important;
}

.boxes .box {
  width: var(--size);
  height: var(--size);
  top: 0;
  left: 0;
  position: absolute;
  transform-style: preserve-3d;
}

.boxes .box:nth-child(1) {
  transform: translate(100%, 0);
  animation: box1 var(--duration) linear infinite;
}

.boxes .box:nth-child(2) {
  transform: translate(0, 100%);
  animation: box2 var(--duration) linear infinite;
}

.boxes .box:nth-child(3) {
  transform: translate(100%, 100%);
  animation: box3 var(--duration) linear infinite;
}

.boxes .box:nth-child(4) {
  transform: translate(200%, 0);
  animation: box4 var(--duration) linear infinite;
}

.boxes .box > div {
  --background: #5c8df6;
  --top: auto;
  --right: auto;
  --bottom: auto;
  --left: auto;
  --translateZ: calc(var(--size) / 2);
  --rotateY: 0deg;
  --rotateX: 0deg;
  position: absolute;
  width: 100%;
  height: 100%;
  background: var(--background);
  top: var(--top);
  right: var(--right);
  bottom: var(--bottom);
  left: var(--left);
  transform: rotateY(var(--rotateY)) rotateX(var(--rotateX))
    translateZ(var(--translateZ));
}

.boxes .box > div:nth-child(1) {
  --top: 0;
  --left: 0;
}

.boxes .box > div:nth-child(2) {
  --background: #145af2;
  --right: 0;
  --rotateY: 90deg;
}

.boxes .box > div:nth-child(3) {
  --background: #447cf5;
  --rotateX: -90deg;
}

.boxes .box > div:nth-child(4) {
  --background: #dbe3f4;
  --top: 0;
  --left: 0;
  --translateZ: calc(var(--size) * 3 * -1);
}

@keyframes box1 {
  0%,
  50% {
    transform: translate(100%, 0);
  }

  100% {
    transform: translate(200%, 0);
  }
}

@keyframes box2 {
  0% {
    transform: translate(0, 100%);
  }

  50% {
    transform: translate(0, 0);
  }

  100% {
    transform: translate(100%, 0);
  }
}

@keyframes box3 {
  0%,
  50% {
    transform: translate(100%, 100%);
  }

  100% {
    transform: translate(0, 100%);
  }
}

@keyframes box4 {
  0% {
    transform: translate(200%, 0);
  }

  50% {
    transform: translate(200%, 100%);
  }

  100% {
    transform: translate(100%, 100%);
  }
}

html {
  -webkit-font-smoothing: antialiased;
}

* {
  box-sizing: border-box;
}

*:before,
*:after {
  box-sizing: border-box;
}

body .dribbble {
  position: fixed;
  display: block;
  right: 20px;
  bottom: 20px;
}

body .dribbble img {
  display: block;
  height: 28px;
}

/* card images fix height */
.card .card-image img {
  height: 250px !important;
}

.calender {
  display: flex;
  align-items: center;
}

.calender input {
  /* border: none; */
  margin-bottom: 0;
  height: 35px;
  border: none;
}

.calender input::placeholder,
.calender mat-datepicker-toggle {
  color: #0077b6;
  font-weight: 500;
}

.calender .arrow {
  background: #0077b6;
  width: 80px;
  height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 15px;
  color: #fff;
  margin-left: 15px;
  padding: 7px;
}

.snackbarClass div {
  background-color: rgb(250, 250, 250);
}

body {
  font-family: Roboto, "Helvetica Neue", sans-serif !important;
}

/* ::ng-deep
  .autocomplete-container.mat-focused
  .mat-form-field-outline
  .mat-form-field-outline-start,
::ng-deep
  .autocomplete-container.mat-focused
  .mat-form-field-outline
  .mat-form-field-outline-gap,
::ng-deep
  .autocomplete-container.mat-focused
  .mat-form-field-outline
  .mat-form-field-outline-end {
  border-bottom: none;
  border-radius: 0;
} */

/* ::ng-deep .mat-form-field-flex {
  width: 100% !important;
}

.mat-form-field {
  min-width: 100%;
}

.mat-form-field-appearance-outline .mat-form-field-wrapper {
  margin: 0px;
  padding-top: 20px;
} */

.pt-2 {
  padding-top: 2rem;
}

.mt-2 {
  margin-top: 2rem;
}

/* label.active {
  color: #5459D1;
}

input.active {
  border-bottom: 1px solid #5459D1;
}

label:focus {
  color: #5459D1;
} */

input.valid:not([type]),
input.valid:not([type]):focus,
input.valid[type="text"]:not(.browser-default),
input.valid[type="text"]:not(.browser-default):focus,
input.valid[type="password"]:not(.browser-default),
input.valid[type="password"]:not(.browser-default):focus,
input.valid[type="email"]:not(.browser-default),
input.valid[type="email"]:not(.browser-default):focus,
input.valid[type="url"]:not(.browser-default),
input.valid[type="url"]:not(.browser-default):focus,
input.valid[type="time"]:not(.browser-default),
input.valid[type="time"]:not(.browser-default):focus,
input.valid[type="date"]:not(.browser-default),
input.valid[type="date"]:not(.browser-default):focus,
input.valid[type="datetime"]:not(.browser-default),
input.valid[type="datetime"]:not(.browser-default):focus,
input.valid[type="datetime-local"]:not(.browser-default),
input.valid[type="datetime-local"]:not(.browser-default):focus,
input.valid[type="tel"]:not(.browser-default),
input.valid[type="tel"]:not(.browser-default):focus,
input.valid[type="number"]:not(.browser-default),
input.valid[type="number"]:not(.browser-default):focus,
input.valid[type="search"]:not(.browser-default),
input.valid[type="search"]:not(.browser-default):focus,
textarea.materialize-textarea.valid,
textarea.materialize-textarea.valid:focus,
.select-wrapper.valid > input.select-dropdown {
  border-bottom: 1px solid #5459d1;
  box-shadow: 0 1px 0 0 #5459d1;
  height: auto !important;
}

input:not([type]):focus.valid ~ label,
input[type="text"]:not(.browser-default):focus.valid ~ label,
input[type="password"]:not(.browser-default):focus.valid ~ label,
input[type="email"]:not(.browser-default):focus.valid ~ label,
input[type="url"]:not(.browser-default):focus.valid ~ label,
input[type="time"]:not(.browser-default):focus.valid ~ label,
input[type="date"]:not(.browser-default):focus.valid ~ label,
input[type="datetime"]:not(.browser-default):focus.valid ~ label,
input[type="datetime-local"]:not(.browser-default):focus.valid ~ label,
input[type="tel"]:not(.browser-default):focus.valid ~ label,
input[type="number"]:not(.browser-default):focus.valid ~ label,
input[type="search"]:not(.browser-default):focus.valid ~ label,
textarea.materialize-textarea:focus.valid ~ label {
  color: #5459d1;
  height: auto;
}

input:not([type]),
input[type="text"]:not(.browser-default),
input[type="password"]:not(.browser-default),
input[type="email"]:not(.browser-default),
input[type="url"]:not(.browser-default),
input[type="time"]:not(.browser-default),
input[type="date"]:not(.browser-default),
input[type="datetime"]:not(.browser-default),
input[type="datetime-local"]:not(.browser-default),
input[type="tel"]:not(.browser-default),
input[type="number"]:not(.browser-default),
input[type="search"]:not(.browser-default),
textarea.materialize-textarea {
  height: auto !important;
}

/* thead tr {
  border-radius: 10px !important;
} */

.cdk-overlay-container {
  z-index: 9999 !important;
}

.title {
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

/* tooltip {
  max-width: calc(100vw / 2 - 50px) !important;
} */

.custom-tooltip {
  background-color: blue;
  color: white;
  max-width: 250px;
  /* Add more styles as needed */
}

.custom-tooltip-count {
  white-space: pre-line;
  min-width: 280px !important;
  max-width: 350px;
  max-height: 250px !important;
  background: #000000bf !important;
  color: white !important;
  font-size: 14px;
  padding: 10px;
  border-radius: 5px;
}

[tooltipDirective] {
  position: relative;
}

.tooltip {
  position: absolute;
  left: 15px;
  padding: 10px;
  background: red;
  border-radius: 5px;
}

.mat-table-content {
  max-width: 100%;
  height: 100%;
  overflow: auto;
}

.mat-mdc-snack-bar-container .mdc-snackbar__surface {
  border-radius: 12px !important;
  /* background-color: #5459D1 !important; */
  max-width: 420px !important;
}

.mdc-snackbar__label {
  border-radius: 12px !important;
  padding: 12px 27px 12px 14px !important;
}

.mdc-snackbar__surface {
  padding: 0 !important;
}

.mat-mdc-snack-bar-container .mdc-snackbar__surface {
  background-color: #fff;
}

.toast-desc-divider {
  margin: 5px 5px 0px 0px;
  color: #5459d1;
  font-weight: bold;
}

.toast-body {
  height: 50px;
  width: 380px;
}

.toast-img {
  height: 60px;
  width: 100px;
  margin-right: 8px;
}

.toast-right {
  margin-left: 25px;
}

.toast-user {
  font-weight: bold;
  color: black;
  text-transform: capitalize;
}

.toast-desc {
  display: inline-block;
  color: black;
  margin-right: 5px;
  margin: 0px 5px 0px 0px;
}

.mat-mdc-dialog-surface {
  width: auto !important;
  height: 97% !important;
  border-radius: 10px !important;
}

.d-flex {
  display: flex;
}

.justify-content-center {
  justify-content: center;
}

.align-items-center {
  align-items: center;
}

.modal {
  border-radius: 10px;
}

@media only screen and (max-width: 1400px) {
  button {
    height: 36px !important;
    font-size: 0.875rem !important;
  }

  button span.material-symbols-outlined {
    font-size: 20px !important;
  }
}

@media only screen and (max-width: 768px) {
  button {
    height: 30px !important;
    font-size: 0.75rem !important;
  }

  button span.material-symbols-outlined {
    font-size: 16px !important;
  }
}

.mdc-text-field--outlined:not(.mdc-text-field--disabled)
  .mdc-notched-outline__leading,
.mdc-text-field--outlined:not(.mdc-text-field--disabled)
  .mdc-notched-outline__notch,
.mdc-text-field--outlined:not(.mdc-text-field--disabled)
  .mdc-notched-outline__trailing {
  border-color: rgba(76, 78, 100, 0.22);
}

.dropdown .mdc-text-field--filled:not(.mdc-text-field--disabled) {
  color: #4c4e6499;
  background-color: #f8f8f9 !important;
  height: 50px;
  display: flex;
  align-items: center;
  border-radius: 8px;
}

.avtar .avatar-content {
  font-size: 18px !important;
  font-family: "Manrope", sans-serif !important;
}

@media only screen and (max-width: 600px) {
  button {
    height: 40px !important;
    font-size: 1rem !important;
  }

  .modal {
    width: 90%;
  }

  *::-webkit-scrollbar {
    width: 5px;
  }

  .mat-mdc-snack-bar-container {
    width: 70vw !important;
    margin: 16px !important;
  }

  .toast-body {
    height: 50px;
    width: 100%;
  }

  .tour-step {
    max-width: 250px !important;
  }
}

.mat-mdc-menu-panel.mat-mdc-menu-panel {
  border-radius: 12px;
  box-shadow: 0px 2.76726px 2.21381px 0px rgba(0, 0, 0, 0.02),
    0px 6.6501px 5.32008px 0px rgba(0, 0, 0, 0.03),
    0px 12.52155px 10.01724px 0px rgba(0, 0, 0, 0.04),
    0px 22.33631px 17.86905px 0px rgba(0, 0, 0, 0.04),
    0px 41.77761px 33.42209px 0px rgba(0, 0, 0, 0.05),
    0px 100px 80px 0px rgba(0, 0, 0, 0.07), 0px 0px 4px 0px rgba(0, 0, 0, 0.16);
}

.mat-mdc-menu-content {
  padding: 0 !important;
}

.mat-mdc-dialog-container .mdc-dialog__surface {
  height: 90% !important;
}

/* media query for above 1024px */
@media only screen and (min-width: 1024px) {
  .mat-mdc-dialog-container .mdc-dialog__surface {
    height: 90% !important;
  }
}

@media only screen and (max-width: 440px) {
  .mat-mdc-dialog-container .mdc-dialog__surface {
    height: 70% !important;
  }
}

@media only screen and (max-width: 380px) {
  .mat-mdc-dialog-container .mdc-dialog__surface {
    height: 90% !important;
  }
}

.tooltip-body p {
  margin-bottom: 0px;
  margin-top: 7px;
  width: 100px;
  width: 109px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: block;
  font-size: 15px;
  color: #bbbbbb;
}

.tooltip-body span {
  margin-bottom: 0px;
  margin-top: 7px;
  width: 100px;
  width: 109px;
  overflow: hidden;
  display: flex;
  font-size: 15px;
  color: #bbbbbb;
  justify-content: center;
  align-items: center;
  text-align: center;
  transform: translateX(85px);
  margin-top: 20px;
  margin-bottom: 20px;
  font-weight: bold;
}

.text-wrap {
  white-space: nowrap;
  width: 20ch;
  overflow: hidden;
  text-overflow: ellipsis;
}

.md-drppicker.double {
  display: flex !important;
  top: auto !important;
  left: auto !important;
  right: 20px !important;
  flex-wrap: wrap !important;
  align-items: flex-end !important;
}

.ipv_dropdown:hover .ipv_selected-item {
  background-color: #fff;
}

.ipv_selected-item {
  margin-right: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  /* border: 1px solid rgb(221 213 213); */
  color: rgba(0, 0, 0, 0.6);
  /* border-radius: 4px; */
  border-top: 1.5px solid #ffe239;
  border-bottom: 5px solid #ffe239;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
  border-bottom-right-radius: 15px;
  border-bottom-left-radius: 15px;
  font-size: 16px;
  height: 70px;
  margin: 0;
}

.ipv_dropdown input:focus {
  border-bottom: none !important;
  box-shadow: none !important;
}

.feedback-content textarea {
  height: 150px !important;
}

#feedback-btn {
  background-color: transparent !important;
  box-shadow: none !important;
}

.feedback-btn-wrapper {
  margin: 1rem !important;
}

.swal2-loader {
  border-color: #32357d transparent #32357d transparent !important;
}
